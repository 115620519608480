.tooltipContainer {
  position: relative;
  display: flex;
  cursor: pointer;
  color: #26a8b0;
  margin: 2px 0px;
  align-items: center;
}

.tooltip {
  visibility: visible;
  background-color: rgba(128, 128, 128, 0.788);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the element */
  left: 80%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipContainer:hover .tooltip {
  opacity: 1;
}
